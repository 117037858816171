
    import { defineComponent } from "vue";
    import Vue from 'vue';
    import vm from '@/main';
    import { sellPlanModel} from "@/models/WorkspaceModel";
    import CategoryNodePath from "@/components/CategoryNodePath.vue";
    import WorkspaceSellplanApi from "@/apis/WorkspaceSellPlanApi";

    export default defineComponent({
        name: "EditSellPlan",
        components: {
            CategoryNodePath,
        },
        data() {
            return {
                currentSellPlan: {
                    price: 0,
                    sellType: '',
                    showImageUrl: '',
                    isRandom: false,
                    uuid: '',
                    isUserChoose: false,
                    userChooseNumber: 0,
                    isBigCourse: false,
                    isDisplay: false,
                    metadata: {name: "",type: "" },
                    timeLimit: 0,
                    numberLimit: 0,
                    description: '',
                    categoryIds: [],
                    categoryPaths: [],
                } as sellPlanModel,
                activeButton: 'course',
                whereToList:'fourQuizList',
                action:''
            };
        },
        computed: {
            actionOfCourse() {
                if(this.$router.currentRoute.path.includes('edit')) this.action = 'edit'
                else    this.action = 'create'
                // if(this.action == 'edit'){
                //     this.currentSellPlan = this.$store.state.currentSellPlanWorkspace
                // }
                this.currentSellPlan = this.$store.state.currentSellPlanWorkspace
                if(this.currentSellPlan.sellType=='' || this.currentSellPlan.sellType==undefined){
                    // this.currentSellPlan.sellType = "COURSE";
                    Vue.set(this.currentSellPlan, 'sellType', "COURSE");
                }
                console.log(this.currentSellPlan.sellType);
            }
        },
        methods: {
            async saveEdit(){
                try{
                    this.$store.commit('updateLoading', true);
                    console.log(this.currentSellPlan);
                    const updateSellPlan = JSON.parse(JSON.stringify(await WorkspaceSellplanApi.updateSellPlan(this.currentSellPlan)));
                    this.$router.push({ path: "/workspace/sellPlan" });
                }catch(err){
                    console.log(err)
                }finally{
                    this.$store.commit('updateLoading', false);

                }
            },
            typeChange(type:string){
                this.currentSellPlan.sellType = type;
                console.log("type change: " + this.currentSellPlan.sellType);
            },
            listChange(list:boolean){
                this.currentSellPlan.isDisplay = list;
            },
            getCategoryId(id: string){
                console.log("getCategoryId: " + id);
                this.currentSellPlan.categoryIds.length = 0
                this.currentSellPlan.categoryIds.push(id);
            }
        },
        created(){
            this.actionOfCourse

        }
    });
